import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import clsx from 'clsx';
import useStyles from '@common_button/style';

const CustomButton = ({
    variant = 'contained',
    className = {},
    buttonType = 'primary',
    disabled,
    user = 'admin',
    ...other
}) => {
    const classes = useStyles();
    const getClassByType = (type) => {
        if (type === 'primary-rounded') {
            return clsx(classes.primary, classes.rounded, disabled && classes.disabled);
        } if (type === 'outlined') {
            return clsx(classes.outlined, disabled && classes.disabledOutlined);
        } if (type === 'outlined-rounded') {
            return clsx(classes.outlined, classes.rounded, disabled && classes.disabledOutlined);
        } if (type === 'buttonText') {
            return clsx(classes.buttonText, disabled && classes.disabledText);
        } if (type === 'link') {
            return clsx(classes.link, disabled && classes.disabledText);
        }
        return clsx(classes.primary, disabled && classes.disabled);
    };
    const customClass = classNames(
        getClassByType(buttonType),
        className,
        user,
    );

    return (
        <Button
            variant={variant}
            className={customClass}
            disabled={disabled}
            {...other}
        />
    );
};

export default CustomButton;
