/* eslint-disable import/prefer-default-export */
export const getStoreLogo = `
{
    getStoreLogo {
        favicon
        logo
        login_side_image
    }
}
`;
