import firebase from 'firebase/app';

require('firebase/auth');
require('firebase/messaging');
require('firebase/firestore');

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = features.pushNotification.config;
const firebaseConfig = {
    apiKey: String(process.env.FIREBASE_API_KEY),
    authDomain: String(process.env.FIREBASE_AUTH_DOMAIN),
    projectId: String(process.env.FIREBASE_PROJECT_ID),
    databaseURL: String(process.env.FIREBASE_DATABASE_URL),
    storageBucket: String(process.env.FIREBASE_STORAGE_BUCKET),
    messagingSenderId: String(process.env.FIREBASE_MESSAGING_SENDER_ID),
    appId: String(process.env.FIREBASE_APP_ID),
    measurementId: String(process.env.FIREBASE_MEASURE_ID),
};

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
