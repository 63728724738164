/* eslint-disable prefer-destructuring */
import {
    getToken, getStoreConfig, getCustomer, getSeller,
} from '@modules/login/services/graphql';
import { expiredToken, custDataNameCookie, sellerDataNameCookie } from '@config';
import { setLogin, removeIsLoginFlagging } from '@helper_auth';
import { useRouter } from 'next/router';
import Layout from '@layout';
import { regexEmail } from '@helper_regex';
import BackdropLoad from '@helper_backdropload';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cookies from 'js-cookie';
import { setLocalStorage, removeLocalStorage } from '@helper_localstorage';
import React from 'react';

const ContentWrapper = (props) => {
    const {
        Content, storeLogo, t, recaptcha,
    } = props;
    const router = useRouter();
    const [confirmNotif, setConfirmNotif] = React.useState(false);
    const [token, setToken] = React.useState();

    const [getSellerData, getSellerDataRes] = getSeller();
    const [getCustomerData, getCustomerDataRes] = getCustomer({
        onCompleted: () => getSellerData(),
    });

    const [getCustomerToken] = getToken();

    const recaptchaRef = React.createRef();

    const handleSubmit = (variables, captcha = '') => {
        getCustomerToken({
            variables, context: { request: 'internal', headers: { 'x-recaptcha': captcha } },
        }).then(async (res) => {
            const tokenRes = res.data.internalGenerateCustomerToken.token;
            if (tokenRes) {
                setToken(tokenRes);
            }
        }).catch((e) => {
            window.backdropLoader(false);
            if (e.message.search('graphql-customer-unconfirmed') >= 0) {
                setConfirmNotif(true);
            } else {
                setConfirmNotif(false);
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: e.message.split(':')[0],
                });
            }
        });
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            captcha: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required(t('login:This_is_a_Required_field')).matches(regexEmail, t('login:Invalid email format!')),
            password: Yup.string().required(t('login:This_is_a_Required_field')),
            captcha: recaptcha.enable && Yup.string().required(`Captcha ${t('registervendor:required')}`),
        }),
        onSubmit: async (values) => {
            const { captcha, ...restValues } = values;
            window.backdropLoader(true);
            if (recaptcha.enable) {
                try {
                    handleSubmit(restValues, captcha);
                } catch {
                    window.backdropLoader(false);
                    window.toastMessage({
                        open: true,
                        variant: 'error',
                        text: t('login:Could_not_verify_captcha'),
                    });
                    recaptchaRef.current.reset();
                }
            } else {
                handleSubmit(restValues);
            }
        },
    });

    React.useEffect(() => {
        if (router.query?.confirm) {
            if (router.query?.confirm === 'success') {
                window.toastMessage({
                    open: true,
                    text: t('login:Thank_you_for_registering_Please_log_in_to_your_account'),
                    variant: 'success',
                });
            } else if (router.query?.confirm === 'failed' && Cookies.get('error_confirmation')) {
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: Cookies.get('error_confirmation'),
                });
            }
        }
    }, []);

    React.useEffect(async () => {
        if (getSellerDataRes?.data || getSellerDataRes?.error) {
            setLocalStorage('refreshAcl', 1);
            // eslint-disable-next-line no-empty
            removeIsLoginFlagging();
            setLogin(1, expiredToken);

            Cookies.remove(custDataNameCookie);
            Cookies.remove(sellerDataNameCookie);

            Cookies.set(custDataNameCookie, getCustomerDataRes.data?.customer);
            if (getCustomerDataRes.data?.customer?.customer_company_code) {
                Cookies.set(sellerDataNameCookie, getSellerDataRes.data?.getSeller);
                router.push('/seller/dashboard');
            } else {
                router.push('/');
            }
        }
    }, [getSellerDataRes]);

    React.useEffect(() => { if (token) getCustomerData(); }, [token]);

    const contentProps = {
        ...props,
        formik,
        storeLogo,
        t,
        recaptchaRef,
        confirmNotif,
    };

    React.useEffect(() => {
        removeLocalStorage('acl');
        removeLocalStorage('config_acl');
        Cookies.remove('katalis');
    }, []);

    return (
        <Content {...contentProps} />
    );
};

const Core = (props) => {
    const { t, storeConfig } = props;

    const pageConfig = {
        title: t('login:Login'),
        header: false,
        sidebar: false,
    };

    const { loading: loadingConfig, data: dataConfig } = getStoreConfig({
        path: 'swiftoms_vendorportal/configuration/enable_vendor_portal',
    });
    React.useEffect(() => {
        BackdropLoad(loadingConfig);
    }, [loadingConfig]);

    if (loadingConfig) {
        return <Layout pageConfig={pageConfig} />;
    }

    const contentProps = {
        ...props,
        recaptcha: {
            enable: storeConfig?.recaptcha_for_customer_login === 'invisible' || storeConfig?.recaptcha_for_customer_login === 'recaptcha',
            sitekey: storeConfig?.recaptcha_for_customer_login === 'invisible'
                ? storeConfig?.recaptcha_v2_invisible_public_key : storeConfig?.recaptcha_v2_public_key,
        },
        dataConfig: dataConfig && dataConfig.getStoreConfig && dataConfig.getStoreConfig === '1',
    };

    return (
        <Layout pageConfig={pageConfig}>
            <ContentWrapper
                {...contentProps}
            />
        </Layout>
    );
};

export default Core;
