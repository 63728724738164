/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
const { GraphQLClient } = require('graphql-request');
const cookies = require('js-cookie');

function requestGraphPim(query, variables = {}, context = {}) {
    const getCookies = cookies.get();

    return new Promise((resolve) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.GRAPHQL_PIM_TOKEN}`,
        };

        const email = (getCookies?.cdt && JSON.parse(getCookies.cdt)?.email) || context.session?.email || '';
        if (email) {
            headers.email = email;
        }

        const language_store_code = (getCookies?.language_store_code && JSON.parse(getCookies?.language_store_code)) || undefined;
        if (language_store_code !== 'undefined' && language_store_code !== undefined) {
            headers.Store = language_store_code;
        }

        const client = new GraphQLClient(`${process.env.GRAPHQL_PIM_URL}`, {
            headers,
        });
        client.request(query, variables).then((data) => resolve(data)).catch((err) => resolve(err));
    });
}

module.exports = requestGraphPim;
