/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import getConfig from 'next/config';

const { GraphQLClient } = require('graphql-request');

const { decrypt } = require('../../../helpers/encryption');

const { publicRuntimeConfig } = getConfig();

function requestGraphInternal(query, variables = {}, context = {}) {
    let token = '';
    if (context.session || context.headers) {
        token = context.session.token ? `Bearer ${decrypt(context.session.token)}`
            : context.headers.authorization ? context.headers.authorization : '';
    }

    return new Promise((resolve) => {
        const headers = {
            Authorization: token,
        };
        const needRecaptcha = ['generateCustomerToken', 'resetPassword', 'changeCustomerPassword', 'createSeller'];
        if (needRecaptcha.some((v) => query?.indexOf(v) >= 0) && context?.headers?.['x-recaptcha']) {
            headers['X-ReCaptcha'] = context?.headers?.['x-recaptcha'];
        }

        const client = new GraphQLClient(`${publicRuntimeConfig.BASE_URL}/graphql`, {
            headers,
        });

        // console.log(client);
        client.request(query, variables).then((data) => resolve(data)).catch((err) => resolve(err));
    });
}

module.exports = requestGraphInternal;
