/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
const { GraphQLClient } = require('graphql-request');
const { decrypt } = require('../../../helpers/encryption');

function requestGraph(query, variables = {}, context = {}) {
    let token = '';
    if (context.session || context.headers) {
        token = context.session.token ? `Bearer ${decrypt(context.session.token)}`
            : context.headers.authorization ? context.headers.authorization : '';
    }
    return new Promise((resolve) => {
        const headers = {
            Authorization: token,
        };

        const getCookies = context?.graphqlContext?.cookies;
        const language_store_code = getCookies?.language_store_code || undefined;
        if (language_store_code !== 'undefined' && language_store_code !== undefined) {
            headers.Store = JSON.parse(language_store_code);
        }

        const needRecaptcha = ['generateCustomerToken', 'resetPassword', 'changeCustomerPassword', 'createSeller'];
        if (needRecaptcha.some((v) => query?.indexOf(v) >= 0) && context?.headers?.['x-recaptcha']) {
            headers['X-ReCaptcha'] = context?.headers?.['x-recaptcha'];
        }

        const client = new GraphQLClient(`${process.env.GRAPHQL_URL}`, {
            headers,
        });
        console.log(client);
        client.request(query, variables).then((data) => resolve(data)).catch((err) => resolve(err));
    });
}

module.exports = requestGraph;
