import NextErrorComponent from 'next/error';
import Core from '@modules/error/pages/default/core';
import Content from '@modules/error/pages/default/components';

const ErrorPage = (props) => <Core {...props} Content={Content} />;

ErrorPage.getInitialProps = async ({ res, err }) => {
    const errorInitialProps = await NextErrorComponent.getInitialProps({
        res,
        err,
    });
    errorInitialProps.namespacesRequired = ['common'];

    return errorInitialProps;
};

export default ErrorPage;
