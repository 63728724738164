/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const storeConfig = `
    {
        storeConfig {
            default_order_grid_period_filter
            google_maps_api_key
            google_map_enabled
            geocoding_api_key
            limit_size_image
            product_image_min_height
            product_image_min_width
            recaptcha_v3_invisible_public_key
            recaptcha_v2_invisible_public_key
            recaptcha_v2_public_key
            recaptcha_for_customer_login
            recaptcha_for_forgot_password
            recaptcha_for_create_new_account
            seller_enable_taxpayer
            seller_enable_id_card
            swiftoms_channel_enable_external_provider
            swiftoms_enable_vendor_portal
            swiftoms_pickpack_batch_enable
            swiftoms_pickpack_wave_enable
            swiftoms_product_enable_external_provider
            swiftoms_vendor_enable_multi_channel
            swiftoms_tada_enable
            swiftoms_rma_request_package_condition
            swiftoms_rma_request_reason
            swiftoms_rma_request_return_stock
            swiftoms_rma_request_return_type
            swiftoms_vendor_enable_chat
            swiftoms_vendor_store_frontend_url
            swiftoms_vendor_carrier_auto_set_pickup_time
            swiftoms_vendor_enable_seller_create_variant
            swiftoms_vendor_enable_store_shipping
            swiftoms_vendor_minimum_payout
            swiftoms_enable_channel_shipping_label_for
        }
    }
`;

export const storeConfigPim = `
    {
        storeConfig {
            weight_unit
            dimension_unit
            enable_company_create_variant
            product_image_min_width
            product_image_min_height
            product_image_max_size
            product_image_max_limit
            katalis_application_enable
            katalis_purchase_credit_url
            upload_product_limit
            channel_product_no_brand
        }
    }
`;

export const availableStores = gql(`
query AvailableStores {
    availableStores {
        store_code
        store_name
        locale
        is_default_store
    }
}`);
