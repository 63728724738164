import { makeVar } from '@apollo/client';

export const storeConfigVar = makeVar({});
export const storeConfigPimVar = makeVar({});
export const storeLogoVar = makeVar({});

export default {
    storeConfigVar,
    storeConfigPimVar,
    storeLogoVar,
};
